export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/admin/AllUsers.vue'),
    meta: {
      pageTitle: 'All Users',
      breadcrumb: [
        {
          text: 'All Users',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId',
    name: 'user',
    component: () => import('@/views/admin/user/User.vue'),
    meta: {
      pageTitle: 'All Users',
      breadcrumb: [
        {
          text: 'All Users',
          to: '/users',
        },
        {
          text: 'User',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-profile',
    name: 'user-profile',
    component: () => import('@/components/user/UserProfile.vue'),
    meta: {
      pageTitle: 'All Users',
      breadcrumb: [
        {
          text: 'All Users',
          to: '/users',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'User',
          to: '/users/:userId',
        },
        {
          text: 'Profile',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-banner-ads',
    name: 'user-banner-ads',
    component: () => import('@/views/admin/user/UserBannerAds.vue'),
    meta: {
      pageTitle: 'All Users',
      breadcrumb: [
        {
          text: 'All Users',
          to: '/users',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'User',
          to: '/users/:userId',
        },
        {
          text: 'Banner Ads',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-media-storage',
    name: 'user-media-storage',
    component: () => import('@/views/admin/user/UserMediaStorage.vue'),
    meta: {
      pageTitle: 'All Users',
      breadcrumb: [
        {
          text: 'All Users',
          to: '/users',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'User',
          to: '/users/:userId',
        },
        {
          text: 'Media Storage',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-devices-group',
    name: 'user-devices',
    component: () => import('@/views/admin/user/UserDevices.vue'),
    meta: {
      pageTitle: 'All Users',
      breadcrumb: [
        {
          text: 'All Users',
          to: '/users',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'User',
          to: '/users/:userId',
        },
        {
          text: 'Devices',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
