import Vue from 'vue'
import axios from 'axios'
import VueCarousel from 'vue-carousel'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2'
import VueVideoPlayer from 'vue-video-player'
import VueCookies from 'vue-cookies'
import moment from 'moment'
import GmapVue from 'gmap-vue'

// import { initializeApp } from 'firebase/app'
// import { getMessaging } from 'firebase/messaging/sw'
import FbService from './auth/fbService'
import router from './router'
import store from './store'
import App from './App.vue'
import 'vuesax/dist/vuesax.css'
// Global Components
import './global-components'
// require videojs style
// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
// import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-it-bigger/dist/vue-it-bigger.min.css'

Vue.use(moment)
Vue.use(VueCookies)
Vue.use(VueCarousel)
Vue.use(
  VueVideoPlayer, /* {
    options: global default options,
    events: global videojs events
  } */
)
// const config = {
//   apiKey: 'AIzaSyDt5Utp6E2vWHM6FNDOTGDUs_BBLxB5n7U',
//   authDomain: 'simi-connect-ce0e6.firebaseapp.com',
//   projectId: 'simi-connect-ce0e6',
//   storageBucket: 'simi-connect-ce0e6.appspot.com',
//   messagingSenderId: '343784360904',
//   appId: '1:343784360904:web:9965e093c7a0eb441b0efd',
// }
// const a = initializeApp(config)
// const messaging = getMessaging(a)
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then(registration => {
//       // messaging.useServiceWorker(registration)
//       console.log(messaging)
//       console.log(`Service Worker Registration (Scope: ${registration.scope})`)
//     })
//     .catch(error => {
//       const msg = `Service Worker Error (${error})`
//       console.error(msg)
//     })
// } else {
//   // happens when the app isn't served over HTTPS or if the browser doesn't support service workers
//   console.warn('Service Worker not available')
// }
// export default messaging

const axiosIns = axios.create({
  baseURL: 'https://simmi-backend.septemsystems.com/',
})
const auth = new FbService(axiosIns)

Vue.prototype.$axios = axiosIns
Vue.prototype.$auth = auth
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyDjAZTbfd38nqpjt_ugSytfzrLDUBlXfcE',
    libraries: 'drawing,places,geometry',
  },
  installComponents: true,
})
// export const storage = firebase.storage()
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.component('GoogleMap', GmapVue.Map)
Vue.component('GoogleMarker', GmapVue.Marker)
// Composition API
Vue.use(VueCompositionAPI)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.use(VueSweetalert2)

Vue.config.productionTip = false
const app = null
auth.initApp(Vue, app, router, store, App)
// new Vue({
//   Vue,
//   app,
//   router,
//   store,
//   App,
//   render: h => h(App),
// }).$mount('#app')
