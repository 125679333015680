import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import dashboard from '@/router/pages/dashboard'
import others from '@/router/pages/others'
import profiles from '@/router/pages/profiles'
import targetAds from '@/router/pages/targetAds'
import mediaStorage from '@/router/pages/mediaStorage'
import devices from '@/router/pages/devices'
import bannerAds from '@/router/pages/bannerAds'
import { adminRoutes } from '@/router/pages/admin'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...adminRoutes,
    ...dashboard,
    ...profiles,
    ...mediaStorage,
    ...devices,
    ...bannerAds,
    ...others,
    ...targetAds,
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach(async (to, from, next) => {
  const { firebaseAuth } = Vue.prototype.$auth
  if (to.meta.requireAuth) {
    if (!firebaseAuth.currentUser) {
      next({
        path: '/login',
      })
    } else {
      try {
        await store.dispatch('user/getUserIfNotExist')
      } catch (e) {
        firebaseAuth.signOut()
        this.$store.dispatch('user/setUser', {})
        next({
          path: '/login',
        })
      }
      const user = store.getters['user/getUser']
      if (user.is_phone_number_verified) {
        if (to.name === 'phone-verification') {
          next({
            name: 'dashboard',
          })
        }
      } else if (to.name !== 'phone-verification') {
        next({
          name: 'phone-verification',
        })
      }
      if (user.is_profile_completed) {
        if (to.name === 'complete-profile') {
          next({
            name: 'dashboard',
          })
        }
      } else if (to.name !== 'complete-profile') {
        next({
          name: 'complete-profile',
        })
      }
      next()
    }
  } else if (firebaseAuth.currentUser) {
    next({
      path: '/',
    })
  }
  next() // make sure to always call next()!
})

export default router
