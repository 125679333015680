import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  updatePassword,
} from 'firebase/auth'
import { initializeApp } from 'firebase/app'

const config = {
  apiKey: 'AIzaSyDt5Utp6E2vWHM6FNDOTGDUs_BBLxB5n7U',
  authDomain: 'simi-connect-ce0e6.firebaseapp.com',
  projectId: 'simi-connect-ce0e6',
  storageBucket: 'simi-connect-ce0e6.appspot.com',
  messagingSenderId: '343784360904',
  appId: '1:343784360904:web:9965e093c7a0eb441b0efd',
}
export default class FbService {
  firebaseApp = null;

  firebaseAuth = null;

  user = null;

  constructor(axios) {
    // this.axiosIns = axios.create({
    //   baseURL: 'http://localhost:3000/',
    // })
    axios.interceptors.request.use(async axiosConfig => {
      const auth = getAuth()
      if (!auth.currentUser) {
        return axiosConfig
      }
      const token = auth.currentUser.accessToken
      if (
        axiosConfig.headers != null
        && axiosConfig.headers['X-Requested-With'] == null
      ) {
        // eslint-disable-next-line no-param-reassign
        axiosConfig.headers = {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${token}`,
          ...axiosConfig.headers,
        }
      }
      // eslint-disable-next-line consistent-return
      return axiosConfig
    })
  }

  // getAxiosInstance() {
  //   return this.axiosIns
  // }

  async initFirebase() {
    await initializeApp(config)
    this.firebaseAuth = getAuth()
    // this.user = this.firebaseAuth.currentUser
  }

  async signInEmailPassword(email, password) {
    try {
      const data = await signInWithEmailAndPassword(
        this.firebaseAuth,
        email,
        password,
      )
      return {
        success: true,
        data,
      }
    } catch (error) {
      let message = ''
      switch (error.code) {
        case 'authFirebase/invalid-email':
          message = 'The provided value for the email user property is invalid. It must be a string email address.'
          break
        case 'authFirebase/user-not-found':
          message = 'There is no existing user record corresponding to the provided identifier.'
          break
        case 'authFirebase/wrong-password':
          message = 'Incorrect password'
          break
        default:
          message = 'Email or password was incorrect'
          break
      }
      return {
        success: false,
        message,
      }
    }
  }

  async createUserEmailAndPassword(email, password) {
    try {
      const data = await createUserWithEmailAndPassword(
        this.firebaseAuth,
        email,
        password,
      )
      return {
        success: true,
        data,
      }
    } catch (error) {
      let errorMessage = ''
      switch (error.code) {
        case 'authFirebase/email-already-in-use':
          errorMessage = 'User already exist,Login Please'
          break
        default:
          errorMessage = 'Already in use'
          break
      }
      return {
        success: false,
        errorMessage,
      }
    }
  }

  async PasswordResetEmail(email) {
    try {
      await sendPasswordResetEmail(this.firebaseAuth, email)
      return {
        success: true,
      }
    } catch (e) {
      return {
        success: false,
        message: e.message,
      }
    }
  }

  async PasswordUpdate(newpassword) {
    try {
      const user = this.firebaseAuth.currentUser
      await updatePassword(user, newpassword)
      return {
        success: true,
      }
    } catch (e) {
      return {
        success: false,
        message: e.message,
      }
    }
  }

  async googlesignin() {
    try {
      this.provider = new GoogleAuthProvider()
      const data = await signInWithPopup(this.firebaseAuth, this.provider)
      // const credential = GoogleAuthProvider.credentialFromResult(data)
      return {
        success: true,
        data,
      }
    } catch (error) {
      const err = GoogleAuthProvider.credentialFromError(error)
      return {
        success: false,
        message: err,
      }
    }
  }

  async facebooksignin() {
    try {
      this.provider = new FacebookAuthProvider()
      const data = await signInWithPopup(this.firebaseAuth, this.provider)
      // const credential = FacebookAuthProvider.credentialFromResult(data)
      return {
        success: true,
        data,
      }
    } catch (error) {
      const err = FacebookAuthProvider.credentialFromError(error)
      return {
        success: false,
        message: err,
      }
    }
  }

  async initApp(Vue, app, router, store, App) {
    await this.initFirebase()
    onAuthStateChanged(this.firebaseAuth, () => {
      if (!app) {
        // eslint-disable-next-line no-param-reassign
        app = new Vue({
          router,
          store,
          render: h => h(App),
        }).$mount('#app')
      }
    })
  }
}
