export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/create-account',
    name: 'create-account',
    component: () => import('@/views/auth/CreateAccount.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/invitation',
    name: 'invitation',
    component: () => import('@/views/auth/PublicPage.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      requireAuth: true,
    },
  },
  {
    path: '/phone-verification',
    name: 'phone-verification',
    component: () => import('@/views/auth/PhoneVerification.vue'),
    meta: {
      layout: 'full',
      requireAuth: true,
    },
  },
  {
    path: '/otp-code',
    name: 'otp-code',
    component: () => import('@/views/auth/OtpCode.vue'),
    meta: {
      layout: 'full',
      requireAuth: true,
    },
  },
  {
    path: '/complete-profile',
    name: 'complete-profile',
    component: () => import('@/views/auth/CompleteProfile.vue'),
    meta: {
      layout: 'full',
      requireAuth: true,
    },
  },
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/views/AccountSetting.vue'),
    meta: {
      navActiveLink: 'dashboard',
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Dashboard',
          to: '/',
        },
        {
          text: 'Account Settings',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
