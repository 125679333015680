import MediaType from '@/common/enums/mediaTypeEnum'

const getFormDataFromJSON = object => {
  const formData = new FormData()
  Object.keys(object).forEach(key => {
    formData.append(key, object[key])
  })
  return formData
}
const userHaveRole = (user, role) => {
  if (user.user_has_role && user.user_has_role.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const userHasRoleElement of user.user_has_role) {
      if (role.includes(userHasRoleElement.role.role)) {
        return true
      }
    }
  }
  return false
}
const getThumbnailOfFile = file => {
  if (file.type_main === MediaType.VIDEO) {
    if (file.thumbnail) {
      return file.thumbnail
    }
    // eslint-disable-next-line global-require
    return require('@/assets/images/simiicons/Video(1).svg')
  }
  return file.file
}
const getLightBoxOfMediaArray = items => {
  const selectedMedia = []
  items.forEach(item => {
    if (item.type_main === MediaType.IMAGE) {
      const media = { // For an image
        type: 'image', // Can be omitted for image
        thumb: item.file,
        src: item.file,
        caption: `<h4>${item.name}</h4>`, // Optional
      }
      selectedMedia.push(media)
    } else {
      const media = { // For a video that can be played in the <video> tag
        type: 'video',
        thumb: item.thumbnail,
        sources: [
          {
            src: item.file,
            type: item.type,
          },
        ],
        caption: `<h4>${item.name}</h4>`,
        width: 800, // Required
        height: 600, // Required
        autoplay: true, // Optional: Autoplay video when the lightbox opens
      }
      selectedMedia.push(media)
    }
  })
  return selectedMedia
}
export {
  getFormDataFromJSON, userHaveRole, getLightBoxOfMediaArray, getThumbnailOfFile,
}
